import React, { useRef, useState, useEffect, FormEvent } from "react";
import AppLayout from "../../app-layouts/AppLayout";
import AdsBanner from "../../app-layouts/AdsBanner";
import apiClient from "../../api-services/ApiClient";
import { errorAlert, successAlert } from "../../app-util/SweetAlert";
import Preloader from "../../app-components/preloader/Preloader";
import { useInterest } from "../../api-services/api-query/Interest/useInterests";
import SuggestionsSideBar from "../../app-components/SuggestionsSideBar";
import { Link, useNavigate } from "react-router-dom";
import { SITELINKS } from "../../app-routes/links";
import { LuChevronLeft } from "react-icons/lu";
import { CameraIcon } from "../../assets/custom-icons";
import { getUser, storeUser } from "../../api-services/process";
import { UserProfileInterface } from "../../app-schemas/user.interface";
import { TERipple } from "tw-elements-react";
import {
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
import PhoneInput from "react-phone-input-2";
import OTPInput from "react-otp-input";
import { toggle_btn } from "../../app-util/helpers";
import ImageCrop from "../../app-components/image-cropper/ImageCrop";
import { FaTelegram, FaWhatsapp } from "react-icons/fa6";
import { FaSms } from "react-icons/fa";

const MakeNewRequest = () => {
  const navigate = useNavigate();
  const user: UserProfileInterface = getUser();

  const interestData = useInterest();

  const usersPackages = [
    { key: "Standard Users", value: "Standard" },
    { key: "Silver Users", value: "Silver" },
    { key: "Gold Users", value: "Gold" },
  ];

  const [countries, setCountries] = useState<
    {
      name: string;
      nationality: string;
      id: any;
    }[]
  >([]);

  const [states, setStates] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [useOtherMeans, setUseOtherMeans] = useState(false);
  const [isCustomRequest, setIsCustomRequest] = useState(true);
  const [isVideo, setIsVideo] = useState(false);

  const [serverError, setServerError] = useState("");
  const [error, setError] = useState("");

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(
    user?.phone_number
  );
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const [captionImage, setCaptionImge] = useState<any>("");
  const [responsers, setResponders] = useState<any>([
    "Standard",
    "Silver",
    "Gold",
  ]);
  const [prespecifiedTextId, setPrespecifiedTextId] = useState<number>(0);
  const [expectedResponse, setExpectedResponse] = useState<number | string>(0);
  const [interestId, setInterestId] = useState<number>(
    user?.interests?.[0]?.id ?? 1
  );
  const [countryId, setCountryId] = useState<number>(user?.country_id ?? 0);
  const [stateId, setStateId] = useState<number>(user?.state_id ?? 0);
  const [cityId, setCityId] = useState<number>(user?.city_id ?? 0);

  const [resendCode, setResendCode] = useState<boolean>(true);
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(0);

  // video-editing section;
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [videoFile, setVideoFile] = useState<any>();
  const [videoLength, setVideoLength] = useState<number | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    handleCountries();
  }, []);

  useEffect(() => {
    if (user?.country_id !== countryId) {
      setCityId(0);
      setStateId(0);
      setStates([]);
      setCities([]);
    }
    if (countryId) handleStates(Number(countryId) ?? 0);
  }, [countryId, user?.country_id]);

  useEffect(() => {
    if (stateId) handleCities(Number(stateId) ?? 0);
  }, [stateId]);

  useEffect(() => {
    if (timeLeft === 0) {
      setResendCode(false);
    }
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      // Clear the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [timeLeft]);

  // Format time into MM:SS
  const formatTime = (seconds: any) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleOTPChange = (otp: any) => {
    setOtp(otp);
  };

  const handleResponders = (value: string) => {
    setResponders((prevResponders: any) => {
      if (prevResponders.includes(value)) {
        return prevResponders.filter((responder: any) => responder !== value);
      } else {
        return [...prevResponders, value];
      }
    });
  };

  const filterInterest = (data: any) => {
    if (data) {
      const uniqueInterests = new Map();

      data.forEach((item: any) => {
        const interest = item.interest;
        uniqueInterests.set(interest.id, interest);
      });
      const interestsArray = Array.from(uniqueInterests.values());

      return interestsArray;
    }

    return [];
  };

  const filterPrespecifiedText = (data: any, id: any) => {
    if (!!data?.length) {
      const res = data.filter((item: any) => item.interest_id === id);

      return res;
    }

    return [];
  };

  const handleIconClick = () => {
    fileInputRef.current?.click();
  };

  const isValidImage = (file: File) => {
    const validTypes = ["image/png", "image/jpg", "image/jpeg"];
    return validTypes.includes(file.type);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (file && isValidImage(file)) {
      const reader = new FileReader();
      reader.onload = (e) => setPreviewImage(e.target?.result as string);
      reader.readAsDataURL(file);

      await uploadFile(file);
    } else {
      alert("Please select a valid PNG, JPG, or JPEG image.");
      setPreviewImage(null);
    }
  };

  const compressImage = (file: File, quality = 0.9): Promise<Blob | null> => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result as string;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Set canvas size to desired dimensions (optional resizing)
          const MAX_WIDTH = 1200; // Resize width if image is too large
          const scaleSize = MAX_WIDTH / img.width;
          canvas.width = MAX_WIDTH;
          canvas.height = img.height * scaleSize;

          // Draw the image on canvas
          ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

          // Compress image to Blob (JPEG with reduced quality)
          canvas.toBlob(
            (blob) => resolve(blob),
            "image/jpeg", // or "image/webp" for web-friendly compression
            quality // Compression quality: 0.1 (low) to 1.0 (high)
          );
        };
      };
    });
  };

  const uploadFile = async (file: File) => {
    try {
      const compressedBlob = await compressImage(file, 0.7); // Adjust quality as needed
      if (!compressedBlob) throw new Error("Failed to compress image");
      setUploadProgress(0);

      const formData = new FormData();
      formData.append("gallery_pix", compressedBlob, file.name);

      const res = await apiClient.post("/upload-gallery-pix", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent: any) => {
          if (progressEvent.total) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted - 25);
          }
        },
      });

      setCaptionImge(res.data.file_name);
      setUploadProgress(100);
    } catch (error) {
      console.error(error); // Display error message from apiClient
      setUploadProgress(0); // Reset progress on failure
    }
  };

  const handleCountries = async () => {
    // setPageLoading(true);
    try {
      const res: any = await apiClient.get("countries");
      setCountries(res.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      errorAlert("");
    } finally {
      // setPageLoading(false);
    }
  };

  const handleStates = async (country: number) => {
    // setPageLoading(true);
    try {
      const res: any = await apiClient.get(`countries/${country}/states`);
      setStates(res.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      errorAlert("");
    } finally {
      // setPageLoading(false);
    }
  };

  const handleCities = async (state: number) => {
    // setPageLoading(true);
    try {
      const res: any = await apiClient.get(`states/${state}/cities`);
      setCities(res.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      errorAlert("");
    } finally {
      // setPageLoading(false);
    }
  };

  const creteNewRequest = async (e: FormEvent) => {
    e.preventDefault();

    if (prespecifiedTextId < 1 || expectedResponse < 1 || !interestId) {
      setServerError("Complete step 1 - 3 before you submit request");
      return;
    }

    try {
      setLoading(true);
      const data: any = {
        prespecified_text_id: prespecifiedTextId,
        expected_response: expectedResponse,
        interest_id: interestId,
      };

      data.city_id = cityId;
      data.state_id = stateId;
      data.country_id = countryId;

      if (isCustomRequest) {
        data.caption_image = captionImage;
      }

      if (user?.active_subscription?.subscription_plan === "GOLD") {
        data.responders_group = responsers.join(",");
      }

      await apiClient.post("connection-request", data);
      successAlert();
      navigate(SITELINKS.MY_REQUESTS);
    } catch (error: any) {
      console.log(error);
      if (error.match("NO PHONE VERIFICATION")) {
        setShowModal(true);
        // return;
      }

      // errorAlert("");
      setServerError(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  // handle-verifiy phone
  const handleVerification = async (e: FormEvent) => {
    e.preventDefault();
    const result = otp.length === 4;

    setError("");
    if (!result) {
      setError("code must be 6 digit in length");
      return;
    }

    try {
      setLoading(true);
      const data = {
        phone: phoneNumber,
        code: otp,
      };

      const res = await apiClient.post("verify-phone-code", data);

      if (res.data.status) {
        successAlert();
        setShowModal(false);

        const myinfoUpdated = { ...user };
        myinfoUpdated.is_phone_verified = true;

        storeUser(myinfoUpdated);

        creteNewRequest(e);
      }
    } catch (error: any) {
      setError(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  // handle-phone-verification code
  const handleResend2FA = async (type: string) => {
    setError("");
    if (!phoneNumber) {
      setError("Cannot complete process, kindly return to sign in");
      return;
    }

    try {
      setLoading(true);
      setResendCode(true);

      let res = await apiClient.post("request-phone-verification", {
        phoneNumber,
        type,
      });

      if (!!res.data.response?.error) {
        setResendCode(false);
        setError(JSON.stringify(res.data.response.error));
      } else {
        setTimeLeft(180);
      }
    } catch (error: any) {
      setResendCode(false);
      setError(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  // Upload or process cropped image
  const handleCroppedImage = async (file: File) => {
    setUploadProgress(0);
    await uploadFile(file);
  };

  const handleVideoFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file); // Create a temporary URL for the video
      setVideoUrl(url);
      setVideoFile(file);

      // Create a video element to extract metadata
      const video = document.createElement("video");
      video.src = url;

      video.onloadedmetadata = () => {
        setVideoLength(video.duration);
      };
    }
  };

  const uploadVideo = async () => {
    setUploadProgress(0);
    setUploadLoading(true);
    try {
      // check if video is more than 1 mins
      if (!!videoLength && videoLength > 300)
        throw new Error("Video should be not be more than 30 seconds");

      const formData = new FormData();
      formData.append("gallery_video", videoFile, videoFile.name);

      setUploadProgress(1);
      const res = await apiClient.post("/upload-gallery-video", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent: any) => {
          if (progressEvent.total) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted - 15);
          }
        },
      });

      setUploadLoading(false);
      setCaptionImge(res.data.file_name);
      setUploadProgress(100);
    } catch (error) {
      setUploadLoading(false);
      console.error(error);
      setUploadProgress(0);
    }
  };

  return (
    <AppLayout title="Make Request">
      <div className="">
        <div className="grid grid-cols-12 gap-8 justify-between w-full">
          {/* side nav with suggestion */}
          <SuggestionsSideBar />

          <form
            onSubmit={creteNewRequest}
            className="col-span-12 md:col-span-8 lg:col-span-7 bg-hc_F4EBFA dark:bg-slate-800 rounded-10px"
          >
            {/* step 1 */}
            <div className="bg-white dark:bg-slate-800 p-6 mt-16">
              <div className="flex justify-end items-center mb-1 mt-10 hidden">
                <Link
                  to={SITELINKS.HOME}
                  className="hidden relative left-[-10px] text-honeConnectPurple flex items-center text-sm font-semibold"
                >
                  <LuChevronLeft className="text-xl mt-[1px]" />{" "}
                  <span>Back</span>
                </Link>
                <p className="text-xs font-medium text-right text-honeConnectGray dark:text-gray-400">
                  STEP 1/4
                </p>
              </div>

              <div>
                <div className="flex gap-1 items-center">
                  <h3 className="text-sm font-semibold dark:text-gray-300">
                    Select your preferred interest
                  </h3>
                  <span className="text-honeConnectOrange text-base font-normal">
                    *
                  </span>
                </div>
                <p className="flex-col gap-3 items-center text-xs font-normal text-honeConnectOrange">
                  Select only one option
                </p>
              </div>

              <div className="grid grid-cols-2 xl:grid-cols-5 gap-y-5 items-center mt-5">
                {filterInterest(interestData.data).map((item: any) => {
                  return (
                    <label
                      key={item.id}
                      className="flex gap-1 items-center xl:col-span-1 custom-checkbox"
                    >
                      <input
                        type="checkbox"
                        name=""
                        id="col1"
                        className="h-5 w-5 accent-purple"
                        onChange={(e) => {
                          setInterestId(item.id);
                        }}
                        checked={item.id === interestId}
                      />
                      <span className="checkmark"></span>
                      <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                        {item.interest}
                      </span>
                    </label>
                  );
                })}
              </div>

              <div className="hidden">
                <div className="flex gap-3 items-center mt-7">
                  <p className="text-xs font-medium text-honeConnectGray">
                    Top Interest
                  </p>
                </div>

                <div className="flex flex-wrap gap-2 mt-1">
                  <p className="text-xs py-1 px-2 border border-solid border-honeConnectGray200 bg-honeConnectGray50 font-medium text-honeConnectGray rounded-sm">
                    Friend
                  </p>
                  <p className="text-xs py-1 px-2 border border-solid border-honeConnectGray200 bg-honeConnectGray50 font-medium text-honeConnectGray rounded-sm">
                    Mingle
                  </p>
                  <p className="text-xs py-1 px-2 border border-solid border-honeConnectGray200 bg-honeConnectGray50 font-medium text-honeConnectGray rounded-sm">
                    Relationship
                  </p>
                </div>
              </div>
            </div>

            {/* step 2*/}
            <div className="bg-white dark:bg-slate-800 p-6 mt-2">
              <p className="text-xs font-medium text-right mt-16 text-honeConnectGray dark:text-gray-400 hidden">
                STEP 2/4
              </p>

              <div>
                <div className="flex gap-1 items-center">
                  <h3 className="text-sm font-semibold dark:text-gray-300">
                    What best describes you?
                  </h3>
                  <span className="text-honeConnectOrange text-base font-normal">
                    *
                  </span>
                </div>

                <p className="flex-col gap-3 items-center text-xs font-normal text-honeConnectOrange">
                  Select only one option
                </p>
              </div>

              <div className="mt-5">
                <div className="flex gap-3 items-center mt-5">
                  <h3 className="text-sm font-medium text-honeConnectGray dark:text-gray-300">
                    Select prespecified text
                  </h3>
                </div>

                <select
                  className="w-full xl:w-1/5 bg-honeConnectGray50 dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300 p-3.5 py-2 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal hover:shadow-[#7600bc0a]"
                  onChange={(e: any) => {
                    setPrespecifiedTextId(e.target.value);
                  }}
                  value={prespecifiedTextId}
                >
                  <option value={0} disabled>
                    Select option
                  </option>
                  {filterPrespecifiedText(interestData.data, interestId).map(
                    (item: any, index: number) => {
                      return (
                        <option key={index} value={item.prespecified_text_id}>
                          {item.prespecified_text?.content}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>
            </div>

            {/* step 3 */}
            <div className="bg-white dark:bg-slate-800 p-6 mt-2">
              <p className="text-xs font-medium text-right mt-16 text-honeConnectGray dark:text-gray-400 hidden">
                STEP 3/4
              </p>

              <div className="flex gap-1 items-center">
                <h3 className="text-sm font-semibold dark:text-gray-300">
                  How many responses would you like?
                </h3>
                <span className="text-honeConnectOrange text-base font-normal">
                  *
                </span>
              </div>

              <h3 className="flex-col gap-3 items-center text-xs font-normal text-honeConnectOrange">
                Select number of response eg 1,2,5,7,10
              </h3>

              <div className="flex gap-3 items-center mt-5">
                <h3 className="text-sm font-medium text-honeConnectGray dark:text-gray-300">
                  Select responses
                </h3>
              </div>

              <select
                className="w-full xl:w-1/5 bg-honeConnectGray50 dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300 p-3.5 py-2 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal hover:shadow-[#7600bc0a]"
                onChange={(e: any) => {
                  setExpectedResponse(e.target.value);
                }}
                value={expectedResponse}
              >
                <option value={0} disabled>
                  Select option
                </option>
                <option value={1}>1 Connect</option>
                <option value={2}>2 Connects</option>
                <option value={3}>3 Connects</option>
                <option value={4}>4 Connects</option>
                <option value={5}>5 Connects</option>
                <option value={6}>6 Connects</option>
                <option value={7}>7 Connects</option>
                <option value={8}>8 Connects</option>
                <option value={9}>9 Connects</option>
                <option value={10}>10 Connects</option>
              </select>
            </div>

            {/* step 4 */}
            <div className="bg-white dark:bg-slate-800 p-6 mt-2 mb-48">
              <p className="text-xs font-medium text-right mt-16 text-honeConnectGray dark:text-gray-400 hidden">
                STEP 4/4
              </p>

              <div className="flex justify-between gap-10 items-center">
                <div className="">
                  <div className="flex gap-1 items-center">
                    <h3 className="text-sm font-semibold  dark:text-gray-300">
                      {/* Make custom request */}
                      Upload Media
                    </h3>
                    <span className="text-honeConnectOrange text-base font-normal"></span>
                  </div>

                  <p className="flex-col gap-3 items-center text-xs font-normal text-honeConnectOrange">
                    {/* Do you want to specify a different location and request
                    image? */}
                    Your media would be displayed for people who want to connect
                    with you.
                  </p>
                </div>

                <div className="hidden">
                  <input
                    className={`${toggle_btn}`}
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    checked={isCustomRequest}
                    onChange={() => setIsCustomRequest(!isCustomRequest)}
                  />
                </div>
              </div>

              <div className="mt-6">
                {isCustomRequest && (
                  <div className="animate-slow-fade-in">
                    <div className="flex gap-5 justify-center pb-3 text-sm">
                      <button
                        type="button"
                        onClick={() => setIsVideo(false)}
                        className={
                          !isVideo
                            ? "bg-gray-200 dark:text-gray-700 rounded-full px-3 py-1 shadow-sm text-xs"
                            : ""
                        }
                      >
                        Picture
                      </button>
                      <button
                        onClick={() => setIsVideo(true)}
                        type="button"
                        className={
                          isVideo
                            ? "bg-gray-200 dark:text-gray-700 rounded-full px-3 py-1 shadow-sm text-xs"
                            : ""
                        }
                      >
                        Video
                      </button>
                    </div>

                    {/* image area */}
                    {!isVideo && (
                      <div className="flex flex-col text-center justify-center text-sm font-medium">
                        <input
                          type="file"
                          ref={fileInputRef}
                          className="hidden"
                          accept="image/png, image/jpg, image/jpeg" // Restrict file types
                          onChange={handleFileChange}
                        />

                        <div className="flex justify-center mt-2 hidden">
                          <img
                            src={previewImage || CameraIcon} // Show preview if available, otherwise CameraIcon
                            className="w-2/3 p-1 rounded-lg text-center border-2 border-dashed border-honeConnectOrange cursor-pointer"
                            onClick={handleIconClick}
                            alt="Upload Preview"
                          />
                        </div>

                        <ImageCrop onImageSelect={handleCroppedImage} />

                        {/* <p>Upload a picture</p> */}
                        {uploadProgress > 0 && (
                          <div className="flex justify-center items-center gap-1 mt-2">
                            <p>{uploadProgress}%</p>

                            <div
                              className={`h-[4px] flex w-full ${
                                uploadProgress === 100
                                  ? "bg-green-700"
                                  : "bg-[#ffb7a0]"
                              } rounded-lg`}
                            >
                              <div
                                className={`h-[4px] ${
                                  uploadProgress === 100
                                    ? "bg-green-700"
                                    : "bg-honeConnectOrange"
                                } rounded-lg`}
                                style={{ width: uploadProgress + "%" }}
                              ></div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {/* video area */}
                    {isVideo && (
                      <div className="mt-4">
                        <h1>Upload a video</h1>
                        <input
                          type="file"
                          accept="video/*"
                          onChange={handleVideoFileChange}
                          className="w-full xl:w-1/5 bg-honeConnectGray50 dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300 p-3.5 py-2 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal hover:shadow-[#7600bc0a]"
                        />

                        {videoUrl && videoLength !== null && (
                          <div>
                            <h2>Video Preview</h2>
                            <video
                              ref={videoRef}
                              controls
                              style={{
                                width: "100%",
                                maxWidth: "600px",
                                margin: "10px 0",
                              }}
                            >
                              <source src={videoUrl} type="video/mp4" />
                              <source src={videoUrl} type="video/webm" />
                              <source src={videoUrl} type="video/ogg" />
                              Your browser does not support the video tag.
                            </video>

                            <p className="text-xs">
                              Video Length: {videoLength.toFixed(2)} seconds
                            </p>

                            {uploadProgress > 0 && (
                              <div className="flex justify-center items-center gap-1 mt-2">
                                <p>{uploadProgress}%</p>

                                <div
                                  className={`h-[4px] flex w-full ${
                                    uploadProgress === 100
                                      ? "bg-green-700"
                                      : "bg-[#ffb7a0]"
                                  } rounded-lg`}
                                >
                                  <div
                                    className={`h-[4px] ${
                                      uploadProgress === 100
                                        ? "bg-green-700"
                                        : "bg-honeConnectOrange"
                                    } rounded-lg`}
                                    style={{ width: uploadProgress + "%" }}
                                  ></div>
                                </div>
                              </div>
                            )}

                            <button
                              onClick={uploadVideo}
                              type="button"
                              className="px-3 text-white disabled:text-gray-400 bg-purple-600 disabled:bg-gray-200 text-xs py-1 rounded-sm"
                              disabled={uploadLoading}
                            >
                              {uploadLoading ? "Uploading..." : "Upload Video"}
                            </button>
                          </div>
                        )}
                      </div>
                    )}

                    {/* Country and State */}
                    <div className="flex flex-col sm:flex-row gap-4 mt-5">
                      <div className="flex flex-col w-full">
                        <div className="flex gap-3 items-center mt-5">
                          <h3 className="text-sm font-medium text-honeConnectGray dark:text-gray-400">
                            Select country
                          </h3>
                        </div>

                        <select
                          value={countryId ?? ""}
                          name="country_id"
                          onChange={(e: any) => {
                            setCountryId(e.target.value);
                          }}
                          className="w-full xl:w-1/5 bg-honeConnectGray50 dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300 p-3.5 py-2 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal hover:shadow-[#7600bc0a]"
                        >
                          <option value="">Select Country</option>
                          {countries?.map((item: any) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="flex flex-col w-full">
                        <div className="flex gap-3 items-center">
                          <h3 className="text-sm font-medium text-honeConnectGray dark:text-gray-400">
                            Select region
                          </h3>
                        </div>
                        <select
                          value={stateId ?? ""}
                          name="state_id"
                          onChange={(e: any) => {
                            setStateId(e.target.value);
                          }}
                          className="w-full xl:w-1/5 bg-honeConnectGray50 dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300 p-3.5 py-2 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal hover:shadow-[#7600bc0a]"
                        >
                          <option value="">Select State</option>
                          {states?.map((item: any) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="flex flex-col w-full">
                        <div className="flex gap-3 items-center">
                          <h3 className="text-sm font-medium text-honeConnectGray dark:text-gray-400">
                            Select closest city
                          </h3>
                        </div>
                        <select
                          value={cityId ?? ""}
                          name="city_id"
                          onChange={(e: any) => {
                            setCityId(e.target.value);
                          }}
                          className="w-full xl:w-1/5 bg-honeConnectGray50 dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300 p-3.5 py-2 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal hover:shadow-[#7600bc0a]"
                        >
                          <option value="">Select Closest City</option>
                          {cities?.map((item: any) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <div className="flex-col gap-3 items-center mt-16">
                  <h3 className="text-sm font-semibold text-black  dark:text-gray-400">
                    Who can respond to this request?
                  </h3>
                  <p className="flex-col gap-3 items-center text-xs font-normal text-honeConnectOrange">
                    Feature only available to Gold Users
                  </p>
                </div>
                <div className="grid grid-cols-2 xl:grid-cols-5 gap-y-5 items-center mt-5">
                  {usersPackages.map((item: any) => {
                    return (
                      <label
                        key={item.key}
                        className="flex items-center xl:col-span-1 custom-checkbox"
                      >
                        <input
                          type="checkbox"
                          id={`col-${item.value}`}
                          className="h-5 w-5 accent-purple"
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleResponders(item.value);
                          }}
                          checked={responsers.includes(item.value)}
                          disabled={
                            user?.active_subscription?.subscription_plan !==
                            "GOLD"
                          }
                        />
                        <span className="checkmark"></span>
                        <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                          {item.key}
                        </span>
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="fixed z-[2] bottom-16 border-t shadow bg-white dark:bg-slate-800 right-0 left-0 px-6 py-3 dark:border-slate-700 rounded-t-xl">
              <p className="text-red-600 text-[10px] mb-1 xl:w-1/2 text-center z-3">
                {serverError}
              </p>
              <button
                type="submit"
                className="bg-honeConnectPurple dark:bg-slate-800 dark:text-gray-300 text-gray-100 w-full p-3 rounded-lg border mb-10 shadow-sm font-medium"
              >
                Make a request
              </button>
            </div>
          </form>

          <AdsBanner />
        </div>
      </div>

      {/* <!-- Modal --> */}
      <TEModal show={showModal}>
        <TEModalDialog className="rounded-0">
          <TEModalContent className="h-[100vh] overflow-scroll rounded-t-none">
            <TEModalHeader className="bg-honeConnectPurple dark:border-slate-700 dark:bg-slate-800 text-white rounded-t-none">
              {/* <!--Modal title--> */}
              <h5 className="text-md font-normal leading-normal text-white">
                Verify your phone
              </h5>

              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => setShowModal(false)}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </TEModalHeader>

            {/* <!--Modal body--> */}
            <TEModalBody className="dark:bg-slate-800">
              <p className="text-xs mb-5 dark:text-gray-300">
                Phone verification is required to create a request for the first
                time!
              </p>

              <form autoComplete="off" autoCorrect="off" className="space-y-2">
                {/* Phone Number */}
                <div className="flex flex-col w-full mb-1">
                  <label
                    htmlFor="phone"
                    className="mb-1 text-sm font-medium  dark:text-gray-400"
                  >
                    Phone Number
                  </label>
                  <PhoneInput
                    country="ng"
                    value={phoneNumber}
                    onChange={(value) => {
                      setPhoneNumber(value);
                    }}
                    inputClass={`text-xs`}
                    inputProps={{ autoComplete: "off" }}
                  />
                </div>

                <div className="text-xs font-medium text-start mb-16 flex-col justify-center">
                  <span className="mb-3 text-sm dark:text-gray-400">
                    Is this phone correct?{" "}
                  </span>

                  {timeLeft > 0 ? (
                    <button
                      type="button"
                      className="text-honeConnectOrange text-xs font-medium text-center"
                    >
                      {formatTime(timeLeft)}
                    </button>
                  ) : (
                    <div className="flex-col gap-3">
                      <button
                        onClick={() => handleResend2FA("Telegram")}
                        type="button"
                        disabled={resendCode}
                        className="flex gap-2 p-2 px-3 bg-purple-500  text-white rounded mt-3 disabled:text-gray-400 disabled:cursor-not-allowed text-honeConnectPurple text-xs font-medium text-center dark:text-gray-200"
                      >
                        <FaTelegram className="text-lg" />
                        <span>Send OTP to Telegram</span>
                      </button>

                      {!useOtherMeans && (
                        <button
                          className="mt-3 text-blue-600 font-medium text-xs"
                          onClick={() => setUseOtherMeans(!useOtherMeans)}
                        >
                          Do not have Telegram? Use other medium.
                        </button>
                      )}

                      {useOtherMeans && (
                        <div className="flex gap-5">
                          <button
                            onClick={() => handleResend2FA("Whatsapp")}
                            type="button"
                            disabled={resendCode}
                            className="flex gap-2 items-center rounded p-2 px-3 bg-green-500 mt-3 text-white  disabled:text-gray-400 disabled:cursor-not-allowed text-honeConnectPurple text-xs font-medium text-center dark:text-gray-200"
                          >
                            <FaWhatsapp className="text-lg" />
                            <span>Via Whatsapp</span>
                          </button>

                          <button
                            onClick={() => handleResend2FA("SMS")}
                            type="button"
                            disabled={resendCode}
                            className="flex gap-2 items-center rounded bg-blue-500 p-2 px-3 text-white mt-3 disabled:text-gray-400 disabled:cursor-not-allowed text-honeConnectPurple text-xs font-medium text-center dark:text-gray-200"
                          >
                            <FaSms className="text-lg" />
                            <span>Via SMS</span>
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="mt-5 pt-5"></div>

                <div className="flex-col justify-center items-center w-full mt-16">
                  <label
                    htmlFor="phone"
                    className="flex-col mb-1 text-sm font-medium mt-5  dark:text-gray-400"
                  >
                    Enter OTP
                  </label>

                  <OTPInput
                    value={otp}
                    onChange={handleOTPChange}
                    numInputs={4}
                    shouldAutoFocus
                    inputStyle={{
                      width: "3rem",
                      height: "3rem",
                      margin: "0 12px 0 0",
                      fontSize: "20px",
                      borderRadius: 8,
                      border: "1px solid #6d7986",
                      background: "#f8f8f8",
                    }}
                    renderInput={(props) => (
                      <input
                        {...props}
                        type="tel"
                        className="dark:text-slate-800"
                      />
                    )}
                  />
                </div>
              </form>
            </TEModalBody>

            <TEModalFooter className="flex gap-3 dark:bg-slate-800 dark:border-slate-700">
              {error && (
                <p className="flex-col w-full text-red-600 text-[10px] mb-1 xl:w-1/2 text-center">
                  {error}
                </p>
              )}

              <TERipple rippleColor="light" className="">
                <button
                  type="button"
                  className="w-full text-red-700 inline-block rounded bg-red-100 px-6 py-3 text-sm font-medium uppercase leading-normal transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </TERipple>

              <TERipple rippleColor="light" className="w-2/3">
                <button
                  disabled={otp.length !== 4 || loading}
                  onClick={handleVerification}
                  type="button"
                  className="disabled:bg-gray-400 w-full inline-block rounded px-6 py-3 text-sm font-medium uppercase text-gray-500 bg-honeConnectPurple  dark:bg-slate-800 border dark:border-slate-300 text-white shadow dark:text-gray-300"
                >
                  {loading ? "Verifying" : "Verify"}
                </button>
              </TERipple>
            </TEModalFooter>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>

      <Preloader loading={loading} />
    </AppLayout>
  );
};

export default MakeNewRequest;
