import React, { useState } from "react";
import Accordion from "./Accordion";

const FAQ: React.FC = () => {
  const [seemore, setSeemore] = useState(false);

  return (
    <div className="">
      <Accordion
        title="What is HoneConnect?"
        answer="HoneConnect is an innovative social networking platform designed to seamlessly connect like-minded individuals with an intuitive user experience that prioritizes user control and privacy."
      />

      <Accordion
        title="How does HoneConnect Work?"
        answer="- Reach out with targeted connection requests that express your intentions clearly.<br><br>
        - Take charge of how many responses you get for each request.<br><br>
        - Enjoy the privacy of your requests with our helpful smart exclusion feature.<br><br>
        - Let responders connect with you so you can build meaningful relationships!"
      />

      <Accordion
        title="What makes HoneConnect special and valuable to you?"
        answer="<strong>Connect with Purpose:</strong> You can easily specify the type of response you're looking for when making a connection!<br><br>
        <strong>Take Control:</strong> You’re in the driver’s seat! You can decide how many responses you want and can close a request whenever you like.<br><br>
        <strong>Your Privacy Matters:</strong> Rest easy knowing that your connection requests won’t be visible to anyone you’ve chosen to exclude. Plus, we have no profile search functionality to keep your information safe.<br><br>
        <strong>Unlimited Free Requests:</strong> Enjoy the freedom of sending as many connection requests as you want—absolutely free! <br><br>
        <strong>A Great User Experience:</strong>  Discover meaningful connections in a friendly and easy-to-navigate interface that keeps things simple and enjoyable!"
      />

      <Accordion
        title="What is a Connection Request?"
        answer="Users can create and share requests that reflect their interests, helping them connect with others who share the same passions. It’s all about building meaningful relationships with like-minded people!"
      />

      {seemore && (
        <div>
          <Accordion
            title="How much time does it take to get connected?"
            answer="Once someone responds to your open request, connecting on the honeconnect app happens in an instant! We're excited to help you make those connections effortlessly!"
          />

          <Accordion
            title="How long does a connection stay established?"
            answer="Your established connections are saved in your profile and are always just a click away! Feel free to access them anytime."
          />

          <Accordion
            title="How long does a connection request stay open?"
            answer="Connection requests will stay open until we receive the number of responses you specified, or until you choose to close them."
          />

          <Accordion
            title="What are Privacy Exclusions, and why do they matter?"
            answer="Exclusions are like a special list for users! They allow you to choose certain contacts who won’t be able to see your open connection requests, helping to keep your interactions private and making you feel more secure."
          />

          <Accordion
            title="Is it possible for me to search for some friends?"
            answer="HoneConnect values your privacy! That’s why we’ve made profiles non-searchable, so you can connect with peace of mind."
          />

          <Accordion
            title="Is it possible for me to have multiple accounts?"
            answer="We kindly ask that each user has only one account linked to their email address or phone number. Thank you for understanding!"
          />

          <Accordion
            title="What’s the best way to reach out to HoneConnect support?"
            answer="If you encounter any issues, please feel free to reach out to us at <a href='mailto:support@honeconnect.com'>support@honeconnect.com</a>"
          />
        </div>
      )}

      <div className="text-right py-3 underline text-honeConnectPurple">
        <button className="border-b border-honeConnectPurple" onClick={() => setSeemore(!seemore)}>
          {!seemore ? "See More" : "Show less"}
        </button>
      </div>
    </div>
  );
};

export default FAQ;
