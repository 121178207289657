import { HoneConnectLogoII } from "../../../assets/img";
import { FaFacebookF, FaInstagram, FaTiktok, FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

const WebsiteFooter = () => {
  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <footer className="bg-honeConnectPurple text-white py-8">
      <div className="w-11/12 mx-auto grid grid-cols-12 items-center justify-center">
        <div className="order-3 sm:order-2 xl:order-1 col-span-12 sm:col-span-7 xl:col-span-3 flex flex-col gap-2 text-center md:text-left">
          <img
            src={HoneConnectLogoII}
            alt="Footer Logo"
            className="w-32 mx-auto md:mx-0 mb-2 md:mb-0"
          />

          <p className="font-medium text-xs">
            &copy;{new Date().getFullYear()} HoneConnect - All rights reserved.
          </p>
        </div>

        <div className="order-2 sm:order-1 xl:order-2 col-span-full sm:col-span-12 xl:col-span-7 flex flex-col mb-10">
          <ul className="flex flex-wrap justify-center items-center gap-4 text-center sm:gap-16 text-xs font-medium">
            <li>
              <button type="button" onClick={() => scrollToSection("benefit")}>
                Our Benefits
              </button>
            </li>
            <li>
              <button type="button" onClick={() => scrollToSection("faq")}>
                FAQs
              </button>
            </li>
            <li>
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>

            <li>
              <Link to="https://wa.me/+2349060102882">Contact Us</Link>
            </li>
          </ul>
        </div>

        <div className="order-1 sm:order-3 xl:order-3 col-span-12 sm:col-span-5 xl:col-span-2 flex flex-col gap-3 text-sm font-medium items-center mb-10 md:mb-0">
          <p className="text-lg">Follow US</p>
          <div className="flex gap-8 text-2xl">
            <a
              href="https://x.com/honeconnect001"
              rel="noreferrer"
              target="_blank"
            >
              <FaXTwitter />
            </a>
            <a
              href="https://instagram.com/hone_connect001"
              rel="noreferrer"
              target="_blank"
            >
              <FaInstagram />
            </a>
            <a
              href="https://facebook.com/honeconnect"
              rel="noreferrer"
              target="_blank"
            >
              <FaFacebookF />
            </a>

            <a
              href="https://tiktok.com/honeconnect"
              rel="noreferrer"
              target="_blank"
            >
              <FaTiktok />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default WebsiteFooter;
