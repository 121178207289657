import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

// tiny-alert-export
export function tinyAlert(type: string = "close", message: string = "") {
  toast.dismiss();
  switch (type) {
    case "info":
      toast.info(message, {
        autoClose: 70000,
      });
      break;
    case "success":
      toast.success(message, {
        autoClose: 70000,
      });
      break;
    case "warning":
      toast.warning(message, {
        autoClose: 70000,
      });
      break;
    case "error":
      toast.error(message, {
        autoClose: 70000,
      });
      break;
    case "close":
      toast.dismiss();
      break;
    default:
      toast.error(message, {
        autoClose: 70000,
      });
  }
}

// full page- preloader
export function pageLoader(action: boolean = false) {
  if (action) {
    MySwal.fire({
      title: "",
      html:
        '<div style="font-size:12px"><img style="width: 25px; display:inline" src="' +
        process.env.PUBLIC_URL +
        '/loading-icon-red.gif" /><br><br> <strong> Please wait...</strong> </div>',
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
    });
  } else {
    MySwal.close();
  }
}

export const truncateText = (text: any, maxLength: any) => {
  if (!!text?.length) {
    if (text?.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
  }
  return text;
};

export function formatTimestamp(date: string, format?: string) {
  let d = new Date(date);

  if (format === "time") {
    return d.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  } else if (format === "date") {
    let y = d.getFullYear();
    let m = d.getMonth();
    let dd = d.getDate();
    let months_of_year = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return `${months_of_year[m] + " " + dd + ", " + y}`;
  } else {
    return d.toDateString() + " " + d.toLocaleTimeString("en-US");
  }
}

export function formatTimestampToRelativeTime(date: string): string {
  const d = new Date(date);
  const now = new Date();
  const diff = now.getTime() - d.getTime(); // Difference in milliseconds

  // Calculate relative time
  const minutes = Math.floor(diff / (1000 * 60));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const weeks = Math.floor(days / 7);

  if (minutes < 60) {
    return `${minutes}m ago`;
  } else if (hours < 24) {
    return `${hours}h ago`;
  } else if (days === 1) {
    return "Yesterday";
  } else if (days < 7) {
    return `${days} days ago`;
  } else if (weeks === 1) {
    return "Last week";
  }

  // Default to formatted date (DD/MM/YY)
  const day = d.getDate().toString().padStart(2, "0");
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const year = d.getFullYear().toString().slice(-2);

  return `${day}/${month}/${year}`;
}
