import { Link } from "react-router-dom";
import WebsiteHeader from "./website-components/WebsiteHeader";
import WebsiteFooter from "./website-components/WebsiteFooter";
import {
  GooglePlayStore,
  FaqImg,
  testimonialImg1,
  testimonialImg2,
  testimonialImg3,
  testimonialImg4,
  testimonialImg5,
} from "../../assets/img";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import FAQ from "./website-components/FAQ";
import { SITELINKS } from "../../app-routes/links";
import { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

const images = [
  "m-1.jpg",
  "m-2.jpg"
];

const WebsiteIndex = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 6000); // Auto slide every 3 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <main className="dark:bg-[#F8FAFC] dark:text-black">
      <WebsiteHeader />
      {/* HERO SECTION */}
      <div className="bg-[#F8FAFC]" id="home">
        <div className="w-full mx-auto">
          <div className="pr-0 lg:pr-20 text-center lg:text-left">
            <div className="w-full mx-auto min-h-[380px]">
              <div className="rounded-lg shadow-lg">
                {images.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    alt={`Slide ${index}`}
                    className={`absolute w-full transition-opacity duration-700 ease-in-out ${
                      index === currentIndex ? "opacity-100" : "opacity-0"
                    }`}
                  />
                ))}
              </div>

              <button
                onClick={prevSlide}
                className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full hidden"
              >
                <ChevronLeft size={24} />
              </button>

              <button
                onClick={nextSlide}
                className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full hidden"
              >
                <ChevronRight size={24} />
              </button>
            </div>

            <div className="flex flex-col items-center justify-center">
              <Link
                to={SITELINKS.REGISTER}
                className="py-3 px-16 font-semibold text-white rounded-[10px] bg-honeConnectPurple mb-5 md:mb-8 lg:mb-4"
              >
                Free Sign Up
              </Link>
            </div>

            {/* <p className="font-medium text-[#6D6D6D] text-[16px] mb-5 md:mb-8 lg:mb-4">
              Download App From
            </p> */}

            <div className="hidden w-full flex flex-col md:flex-row items-center md:items-start gap-8 justify-center lg:justify-normal">
              <a href="https://google.com" className="block">
                <img src={GooglePlayStore} alt="" className="h-14" />
              </a>

              {/* <a href="https://applestore.com">
                <img src={AppleStore} alt="" className="h-14" />
              </a> */}
            </div>
          </div>

          {/* <div className="hidden md:block mx-auto">
            <img src={HeroImg} alt="" className="md:h-[500px]" />
          </div> */}
        </div>
      </div>


      <div className="w-11/12 mx-auto py-10 lg:py-16 grid">
        <h1 className="text-[25px] md:text-[40px] font-bold mb-5 md:mb-16 text-center">
          About
          <span className="text-honeConnectPurple"> HoneConnect</span>
        </h1>
        <p className="text-center text-xs">
          HoneConnect is not just another social networking app — it’s a
          revolution in how like-minded people connect, interact, and build
          relationships. Designed for those who value privacy, authenticity, and
          meaningful connections, HoneConnect gives you the power to choose who
          sees you, who interacts with you, and how you engage with your
          network. With Targeted Connections, you meet people who truly align
          with your interests. Our Smart Exclusions feature ensures you avoid
          unnecessary noise, and our Exclusivity Mode gives you complete control
          over your visibility. Whether you're here to expand your professional
          network, discover new friendships, or engage in private social
          circles, HoneConnect is built for real connections, on your terms.
          Join a new era of social networking—where your privacy, preferences,
          and control come first. Welcome to HoneConnect.
        </p>
      </div>

      {/* Hone Connect Benefit Section*/}
      <div className="w-11/12 mx-auto py-10 lg:py-16 grid" id="benefit">
        {/* ROW */}
        <h1 className="text-[25px] md:text-[40px] font-bold mb-10 md:mb-16 text-center">
          Your Social
          <span className="text-honeConnectPurple"> Superpowers</span>
        </h1>
        {/* ROW */}
        <div className="grid md:grid-cols-3 justify-between text-center lg:w-9/12 mx-auto gap-10 lg:gap-20">
          <div className="flex flex-row md:flex-col gap-5 md:gap-2">
            <div className="border-[5px] border-honeConnectPurple rounded-[20px] p-4 md:p-8 mx-auto mb-5">
              <img
                src="1.jpg"
                alt=""
                className="max-w-[40px] md:w-11 rounded-[5px]"
              />
            </div>
            <div className="flex flex-col  text-left md:text-center">
              <p className="text-[16px] md:text-xl font-bold text-[#232630]">
                Targeted Connection
              </p>
              <p className="text-[#6D6D6D] font-medium md:font-semibold text-sm">
                Connect with the right people based on shared interests, values,
                and goals.
              </p>
            </div>
          </div>

          <div className="flex flex-row md:flex-col gap-5 md:gap-2">
            <div className="hidden md:block border-[5px] border-honeConnectPurple bg-honeConnectPurple rounded-[20px] p-4 md:p-8 mx-auto mb-5">
              <img
                src="2.jpg"
                alt=""
                className="max-w-[40px] md:w-11 rounded-[5px]"
              />
            </div>
            <div className="block md:hidden border-[5px] border-honeConnectPurple rounded-[20px] p-4 md:p-8 mx-auto mb-5">
              <img
                src="2.jpg"
                alt=""
                className="max-w-[40px] md:w-11 rounded-[5px]"
              />
            </div>
            <div className="flex flex-col  text-left md:text-center">
              <p className="text-[16px] md:text-xl font-bold text-[#232630]">
                Smart Exclusions
              </p>
              <p className="text-[#6D6D6D] font-medium md:font-semibold text-sm">
                Stay in control by filtering out unwanted interactions and
                connections.
              </p>
            </div>
          </div>

          <div className="flex flex-row md:flex-col gap-5 md:gap-2">
            <div className="border-[5px] border-honeConnectPurple rounded-[20px] p-4 md:p-8 mx-auto mb-5">
              <img
                src="4.jpg"
                alt=""
                className="max-w-[40px] md:w-11 rounded-[5px]"
              />
            </div>
            <div className="flex flex-col  text-left md:text-center">
              <p className="text-[16px] md:text-xl font-bold text-[#232630]">
                Free Connection Requests
              </p>
              <p className="text-[#6D6D6D] font-medium md:font-semibold text-sm">
                Expand your network without limits—no hidden fees or
                restrictions.
              </p>
            </div>
          </div>

          <div className="flex flex-row md:flex-col gap-5 md:gap-2">
            <div className="border-[5px] border-honeConnectPurple rounded-[20px] p-4 md:p-8 mx-auto mb-5">
              <img
                src="3.jpg"
                alt=""
                className="max-w-[40px] md:w-11 rounded-[5px]"
              />
            </div>
            <div className="flex flex-col  text-left md:text-center">
              <p className="text-[16px] md:text-xl font-bold text-[#232630]">
                Enhanced Social Experience
              </p>
              <p className="text-[#6D6D6D] font-medium md:font-semibold text-sm">
                Enjoy a seamless platform designed for meaningful interactions.
              </p>
            </div>
          </div>

          <div className="flex flex-row md:flex-col gap-5 md:gap-2">
            <div className="border-[5px] border-honeConnectPurple rounded-[20px] p-4 md:p-8 mx-auto mb-5">
              <img
                src="5.jpg"
                alt=""
                className="max-w-[40px] md:w-11 rounded-[5px]"
              />
            </div>
            <div className="flex flex-col  text-left md:text-center">
              <p className="text-[16px] md:text-xl font-bold text-[#232630]">
                Verified Connections
              </p>
              <p className="text-[#6D6D6D] font-medium md:font-semibold text-sm">
                Build trust with authenticated profiles, ensuring real and
                genuine interactions.
              </p>
            </div>
          </div>

          <div className="flex flex-row md:flex-col gap-5 md:gap-2">
            <div className="border-[5px] border-honeConnectPurple rounded-[20px] p-4 md:p-8 mx-auto mb-5">
              <img
                src="6.jpg"
                alt=""
                className="max-w-[40px] md:w-11 rounded-[5px]"
              />
            </div>
            <div className="flex flex-col  text-left md:text-center">
              <p className="text-[16px] md:text-xl font-bold text-[#232630]">
                Stealth Mode User Control
              </p>
              <p className="text-[#6D6D6D] font-medium md:font-semibold text-sm">
                Browse and engage discreetly without appearing online or
                notifying others.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Frequently Asked Questions Section*/}
      <div className="w-11/12 mx-auto py-10 grid" id="faq">
        <div className="grid grid-cols-12 items-center">
          <div className="md:order-2 lg:order-1 col-span-12 lg:col-span-8 lg:pr-40">
            <h1 className="text-center lg:text-left text-[25px] md:text-[40px] font-bold mb-10 md:mb-16 text-[#061737]">
              Frequently
              <span className="text-honeConnectPurple"> Asked Questions</span>
            </h1>
            <div className="flex flex-col gap-5">
              <FAQ />
            </div>
          </div>
          <div className="md:order-1 lg:order-2 col-span-12 lg:col-span-4 mx-auto hidden lg:block">
            <img src={FaqImg} alt="" className="h-[400px]" />
          </div>
        </div>
      </div>

      {/* Testimonials Section*/}
      <div className="bg-[#F8FAFC] hidden">
        <div className="w-11/12 lg:w-8/12 mx-auto py-10 grid text-center">
          {/* Heading */}
          <h1 className="text-[25px] md:text-[40px] font-bold mb-10 md:mb-16 text-center">
            Our<span className="text-honeConnectPurple"> Testimonials</span>
          </h1>
          {/* Image Slider */}
          <div className="mb-4 flex items-center gap-2 md:gap-8 lg:gap-14 mx-auto">
            <Link to="/" className="text-honeConnectPurple">
              <LuChevronLeft className="text-[40px]" />
            </Link>
            <Link to="/">
              <img src={testimonialImg1} alt="" className="w-20 rounded-full" />
            </Link>
            <Link to="/">
              <img src={testimonialImg2} alt="" className="w-20 rounded-full" />
            </Link>
            <Link to="/">
              <img
                src={testimonialImg3}
                alt=""
                className="active w-32 rounded-full border-solid border-honeConnectPurple border-[5px]"
              />
            </Link>
            <Link to="/">
              <img src={testimonialImg4} alt="" className="w-20 rounded-full" />
            </Link>
            <Link to="/">
              <img src={testimonialImg5} alt="" className="w-20 rounded-full" />
            </Link>
            <Link to="/" className="text-honeConnectPurple">
              <LuChevronRight className="text-[40px]" />
            </Link>
          </div>
          {/* Slider Caption  */}
          <div className="lg:px-52">
            <p className="mb-10 font-semibold text-sm md:text-lg">Taiwo</p>
            <div className="relative mt-14">
              <div className="triangle-up w-0 h-0 border-solid border-l-[25px] border-r-[25px] border-b-[50px] border-transparent border-b-honeConnectPurple rounded-[4px] absolute left-[46%] top-[-25%]"></div>
              <p className="text-xs md:text-sm font-medium md:font-semibold text-white bg-honeConnectPurple p-10 rounded-[20px]">
                Hone Connect has become a valuable part of my online experience,
                and I wouldn't hesitate to recommend it to others looking for a
                dynamic and enriching platform.
              </p>
            </div>
          </div>
        </div>
      </div>

      <WebsiteFooter />
    </main>
  );
};

export default WebsiteIndex;
