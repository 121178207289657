import React, { createContext, useContext, useState, useEffect } from "react";

interface ThemeContextProps {
  isDarkMode: boolean;
  toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextProps>({
  isDarkMode: false,
  toggleTheme: () => {},
});

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    const metaThemeColor = document.querySelector("meta[name='theme-color']");
    const metaThemeColorMs = document.querySelector("meta[name='msapplication-TileColor']");
    const metaThemeColorApple = document.querySelector(
      "meta[name='apple-mobile-web-app-status-bar-style']"
    );
    if (metaThemeColor) {
      metaThemeColor.setAttribute(
        "content",
        isDarkMode ? "#1e293b" : "#ffffff"
      );
    }
    if (metaThemeColorApple) {
      metaThemeColorApple.setAttribute(
        "content",
        isDarkMode ? "#1e293b" : "#ffffff"
      );
    }
    if (metaThemeColorMs) {
      metaThemeColorMs.setAttribute(
        "content",
        isDarkMode ? "#1e293b" : "#ffffff"
      );
    }





    if (isDarkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [isDarkMode]);

  const toggleTheme = () => setIsDarkMode(!isDarkMode);

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
